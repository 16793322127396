


































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import ProductMasterSidebar from '@/components/product-master-sidebar.vue';
import HRMService from "../../../services/hrm-service";

@Component({
  components: {
    Header,
    ProductMasterSidebar
  },
})
export default class InventoryMasterIndex extends Vue {
  private data: any[] = [];
  private currentItem: any = null;
  private currentIndex: number = -1;
  private title: string = "";
  private rows = 100;
  private currentPage = 1

  public fields = [
          {
            key: 'sku',
            sortable: false
          },
          {
            key: 'title',
            sortable: false
          },
          {
            key: 'project_tile',
            label: 'Location',
            sortable: false
          },
          {
            key: 'qty',
            sortable: false
          },
         
        ];

public columns = [

          {
             label: 'sku',
            field: 'sku',
          },
          {
             label: 'title',
            field: 'title',
          },
         {
            label: 'title_arabic',
            field: 'title_arabic'
          },
          {         
            label: 'description',
            field: 'description',
          },
          {
            label: 'qty',
            field: 'no_of_pieces',
          },
          {
         
            label: 'category',
            field: 'category',
            
          },
          {
            label: 'sub_category_level_1',
            field: 'sub_category_level_1',
            
          },
          {
            label: 'sub_category_level_2',
            field: 'sub_category_level_2',
            
          }
          ];
               




  public items:any = []

  retrieve() {
    HRMService.getproductStock()
      .then((response) => {
        this.items = response.data;
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieve();
    this.currentItem = null;
    this.currentIndex = -1;
  }

  mounted() {
    this.retrieve();
  }
}
